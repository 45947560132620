@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    font-weight: 100 900;
    font-display: block;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url('/public/fonts/Inter-roman.var.woff2') format('woff2');
}
@font-face {
    font-family: 'Inter';
    font-weight: 100 900;
    font-display: block;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url('/public/fonts/Inter-italic.var.woff2') format('woff2');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
    min-height: 100%;
}